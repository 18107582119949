<template>
  <div class="header-box">
    <div class="left flex items-middle">
      <div v-if="isCollapse">
        <el-button
          plain
          size="mini"
          icon="iconfont icon-outdent-copy"
          @click="handleMenu()"
        ></el-button>
      </div>
      <div v-else>
        <el-button
          plain
          size="mini"
          icon="iconfont icon-indent-copy "
          @click="handleMenu()"
        ></el-button>
      </div>
      <div>
        <breadcrumb></breadcrumb>
      </div>
    </div>
    <div class="right flex items-middle">
      <div class="msg" @click="toMessage()" v-auth="btnAuth.msg">
        <el-badge is-dot class="item"
          ><i class="el-icon-message-solid"></i
        ></el-badge>
      </div>
      <el-dropdown>
        <div class="el-dropdown-link" align="center">
          <img src="../assets/imgs/avatar.png" alt="" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="text-align: center" v-auth="btnAuth.msg"
            ><span @click="toMessage()">我的消息</span></el-dropdown-item
          >
          <el-dropdown-item style="text-align: center"
            ><span @click="loginOut()">退出</span></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <div class="userName">
        <span>{{ user.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "./breadcrumb.vue";
export default {
  components: { breadcrumb },
  data() {
    return {
      user: {
        avatar: "../assets/imgs/avatar.png",
        name: "",
      },
      btnAuth: {
        msg: "msgBtn",
      },
    };
  },
  mounted() {
    this.getUserName();
  },
  computed: {
    isCollapse() {
      return this.$store.state.slider.isCollapse;
    },
  },
  methods: {
    loginOut() {
      console.log("3333333333333", this.$router);
      this.$router.push("/login");
    },
    handleMenu() {
      this.$store.commit("slider/COLLAPSE_MENU");
      // this.$store.commit("collapseMenu");
    },
    getUserName() {
      this.user.name = localStorage.getItem("stewardUser");
    },
    toMessage() {
      this.$router.push("/message");
    },
  },
};
</script>

<style lang="stylus" scoped>
.header-box{
    height: 100%
    width calc(100% - 40px)
    display: flex
    justify-content: space-between
    align-items: center
    background: -webkit-linear-gradient(top,#0a1e39,#051424);
    padding: 0 20px
}
.el-dropdown-link{
    width 40px
    height 40px
    padding: 0 20px
    img{
        width 100%
        border-radius: 50%
    }
}

/deep/ .el-button.is-plain:focus, .el-button.is-plain:hover{
  background: transparent !important
}
/deep/ .el-button{
  background: transparent !important
  color: #fff
  border: 0px
}
/deep/ .el-button--mini{
  padding: 7px 5px
}
</style>
