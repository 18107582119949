<template>
  <div class="home-container">
    <el-container>
      <el-aside width="auto"><slider></slider></el-aside>
      <el-container style="min-width: 1040px">
        <el-header><header-box></header-box></el-header>
        <div class="main-content">
          <router-view class="main-view"></router-view>
          <div class="bottom">
            <div style="text-algin: center">
              版权所有：深圳市未蓝新能源科技公司
            </div>
            <div>
              COPYRIGHT VILION(SHENZHEN) NEW ENERGY TECHNOLOGY CO., LTD. ALL
              RIGHTS RESERVED
            </div>
          </div>
        </div>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import slider from "../components/slider.vue";
import headerBox from "../components/header.vue";
import router from "@/router";
import store from "@/store";
import { getMenu } from "@/api/login.js";
import { handlePermissionBtn } from "@/utils/util";
// import I18n from "@/lang";
export default {
  components: { slider, headerBox },
  data() {
    return {
      // activeMenu: "/siteList",
      activeMenu: "",
      menuList: [
        {
          list: [
            {
              menuName: "站点信息列表",
              path: "/package-lock.jsonsiteList",
              id: "0101",
              parentId: "01",
            },
          ],
          menuName: "设备管理",
          path: "",
          id: "01",
          icon: "\ue751",
        },
      ],
      tokenTimer: null,
    };
  },
  // watch: {
  //   $route(route) {
  //     // this.activeMenu = route.path;
  //     // console.log(5555, this.activeMenu, route.matched);
  //   },
  // },
  // async beforeRouteEnter(to, from, next) {
  //   // console.log(to.path);

  //   let { data } = await getMenu({});
  //   let data2 = handlePermissionBtn(data.data);
  //   console.log("==================", data2);
  //   store.commit("setMenuTree", data.data);
  //   store.commit("setPermissinBtn", data2);

  //   const token = localStorage.getItem("stewardToken");

  //   if (!token) {
  //     router.replace({ name: "Login" });
  //     return;
  //   }
  //   // 这个时长是要拿来判断token是否失效
  //   const expireTime = localStorage.getItem("stewardExpireTime");
  //   let startTime = new Date().getTime();
  //   console.log(startTime);
  //   if (startTime > expireTime) {
  //     localStorage.removeItem("stewardToken");
  //     router.replace({ name: "Login" });
  //     return;
  //   }
  //   if (to.path === "/") {
  //     next((vm) => {
  //       vm.$router.replace({ name: "Home" });
  //     });
  //   } else {
  //     // console.log(55555, to);
  //     next((vm) => {
  //       vm.$router.replace({ name: to.name, query: to.query });
  //     });
  //   }
  // },
};
</script>
<style lang="stylus" scoped>
.home-container {
  display: flex;
  height: 100vh;
  .main-left {
    height: 100%;
  }

  .main-right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y:scroll;
    overflow-x: scroll
    box-sizing: border-box;

    .main-header {
      height: 60px;
      color: #333;
      text-align: center;
      padding: 0;
    }

    .main-content {
      background: linear-gradient(90deg,#263873,#0b1131)
      .main-view {
        min-width: 1200px;
        box-sizing: border-box;
      }

      .bottom {
        font-size: 12px;
        color: #fff;
        text-align: center;
        background: red
        height: 30px !important;
        margin-top: 20px;
      }
    }
  }
}
.bottom {
        font-size: 12px;
        color: #fff;
        text-align: center;
        // background: red
        height: 30px !important;
        margin-top: 20px;
      }
</style>
