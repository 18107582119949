<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <div class="flex">
        <div @click="toBack()" style="font-size: 14px; color: #3b5f91">
          {{ $route.meta.parentTitle }}
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
      </div>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__item {
  cursor: pointer !important;
}
.el-breadcrumb {
  cursor: pointer !important;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  cursor: pointer !important;
}
</style>
