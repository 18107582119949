<template>
  <div class="slider">
    <el-menu
      router
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color="#002140"
      text-color="#69788f"
      active-text-color="#69788f"
      style="height: 100%"
      :default-active="activeMenu"
      :collapse-transition="false"
      :unique-opened="false"
      @select="handleSelect"
    >
      <div class="logo" align="center">
        <span v-if="isCollapse">
          <img src="../assets/imgs/logo1.png" alt style="width: 40px" />
        </span>
        <span v-else>
          <img src="../assets/imgs/logo2.png" alt style="width: 180px" />
        </span>
      </div>
      <template v-for="(item, index) in menuTree">
        <el-submenu v-if="item.children" :key="index" :index="item.id + ''">
          <template slot="title">
            <i
              class="iconfont item"
              :class="item.icon"
              :style="isCollapse ? 'margin:0' : ''"
            ></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item v-for="(k, j) in item.children" :index="k.url" :key="j">
            <i
              class="iconfont k"
              :class="k.icon"
              :style="isCollapse ? 'margin:0 18px 0 18px' : ''"
            ></i>
            <span slot="title">{{ k.name }}</span>
          </el-menu-item>
        </el-submenu>
        <div class="home" v-else :key="index">
          <el-menu-item
            :index="item.url"
            style="background: #002140 !important"
            :style="isCollapse ? 'padding:0 !important' : ''"
          >
            <i
              class="iconfont item"
              :class="item.icon"
              :style="isCollapse ? 'margin:0;' : ''"
              style="color: #fff"
            ></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </div>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["menuTree"]),
    isCollapse() {
      return this.$store.state.slider.isCollapse;
    },
    activeMenu() {
      return this.$route.path;
    },
  },
  methods: {
    handleSelect(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="stylus" scoped>
.slider {
  width: 100%;
  height: 100%;
}

.logo {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.vilion {
  color: #EDEEFF;
  font-size: 18px;
  margin: 0 15px;
  font-family: myFirstFont;
}

img {
  vertical-align: middle;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

/deep/ .el-menu {
  border: 1px solid rgba(77, 150, 214, 0.3);
  // border-image:linear-gradient(to right,#000718,#23b7cb) 5 5!important;
}

/deep/ .el-submenu__title {
  font-size: 14px !important;
  text-align: center !important;
}


.item {
  font-size: 20px !important;
  margin:0 20px 0 -30px
}

.k {
  font-size: 16px !important;
  margin:0 20px 0 -30px
}


/deep/ i {
  margin-right: 5px;
}

/deep/ .el-menu {
  // border-right: 1px solid #053e61 !important
  border-image: linear-gradient(to right, #000718, #23b7cb) 5 5 !important;
}


/deep/ .el-menu-item {
  // background: #0D1A2D !important
  background: #061220 !important;
  display: flex;
  padding: 0 70px !important;
  text-align: center !important;
}

/deep/ .el-menu-item i {
  width: 20%;
}

/deep/ .el-menu-item span {
  width: 100%;
}
.home /deep/ .el-menu-item {
  // background: #0D1A2D !important
  background: #002140 !important;
  display: flex;
  padding: 0 70px !important;
  text-align: center !important;
}
</style>
